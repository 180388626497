
@import "@variables";

.geo-fences-map {
  .custom-legend {
    flex-direction: column;
    min-width: 130px;

    img {
      height: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .geo-fence-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 20px;
  }

  .geo-fence-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .cost-centers-filter,
    .docking-stations-filter {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

  }
  .title-filter {
    font-size: 16px;
    color: $dark-blue;
    font-weight: 600;
    opacity: 0.5;
    line-height: 22px;
    margin-bottom: 6px;
    font-family: $avenir-next-demi;
  }
}

.bikes-container {
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap; /* Allows items to move to the next row */
  gap: 10px; /* Space between items */
}

.bike-item {
  padding: 5px 10px;
  background: rgb(238, 235, 235);
  border-radius: 5px;
  white-space: nowrap; /* Prevents text from breaking */
}

