// BUTTON
.el-button,
.el-button {
  border: none !important;

  &:focus,
  &:hover {
    color: unset !important;
    border: none;
  }
}

.custom-tracker-btn{
  background: $tab-view-color !important;
  padding: 10px !important;
  color: $white !important;
  margin: 0 !important;
  // margin-right: 10px !important;

  &:focus,
  &:hover {
    color: $white !important;
    border: none;
  }
}

// TABLES BTNS
.custom-table-btn,
.custom-table-btn-dark,
.custom-table-btn-pale {
  background: $tab-view-color !important;
  padding: 13px 23px !important;
  color: $white !important;
  margin-left: 0 !important;
  margin-right: 10px !important;

  &:focus,
  &:hover {
    color: $white !important;
    border: none;
  }
}

.custom-table-btn-dark {
  background: $dark-blue !important;
}

.custom-table-btn-pale {
  background: $pale-blue !important;
}

// ACORDION
.custom-accordion {
  .el-collapse {
    border-top: 0 !important;
  }

  .el-collapse-item__arrow {
    font-size: 20px;
  }
}

// LABELS
.el-form-item__label {
  color: $secondary !important;
  font-family: $avenir-next-demi;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.el-form-item__error {
  word-break: break-word;
}

// TEXT INPUT
.el-input.is-active .el-input__inner,
.el-input__inner:focus,
.el-textarea__inner:focus {
  border-color: $dark-blue !important;
}

.docking-stations-wrapper,
.docking-station-logs-wrapper,
.geo-fences-wrapper,
.customers-wrapper,
.roles-table-wrapper,
.bikes-wrapper,
.trackers-wrapper,
.logs-wrapper,
.technical_dashboard-wrapper,
.users-wrapper,
.roles-wrapper,
.rides-wrapper,
.rent-tariffs-wrapper,
.cost-centers-wrapper,
.inventory-wrapper,
.station-commands-wrapper,
.tickets-wrapper,
.tracker-wrapper {
  .actions {
    .el-input__inner {
      border-top: unset;
      border-left: unset;
      border-right: unset;
      border-radius: 0;
      border-color: $dark-blue;
      font-family: $avenir-next-demi;
      height: unset;
      line-height: unset;
      padding: 10px;

      &:hover {
        color: $dark-blue;
      }
    }

    .el-input__icon,
    input::placeholder {
      color: $dark-blue;
      font-weight: 500;
      line-height: 23px;
    }

    .el-input--prefix .el-input__inner {
      padding: 10px 0;
      padding-left: 40px;
      font-size: 17px;
    }
  }
}

// DIALOG
.el-dialog {
  border-radius: 14px !important;
  padding: 15px;
}

.el-dialog__title {
  color: $dark-blue !important;
  font-family: $avenir-next-bold;
  font-size: 22px !important;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 27px !important;
}

.el-dialog__header {
  padding: 20px !important;
  border-bottom: 1px solid $lighter-gray !important;
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: $dark-blue !important;
}

.el-dialog__headerbtn,
.el-message-box__headerbtn {
  .el-dialog__close,
  .el-message-box__close {
    background: $pop-up-close-bg;
    color: $white !important;
    border-radius: 4px;
    padding: 2px;
  }

  .el-icon-close:before {
    font-weight: bolder;
  }
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: $white !important;
}

// ticket dialog body
.view-ticket-details-dialog {
  .el-dialog__body {
    width: 100% !important;
    padding: 0 !important;
  }

  .el-carousel__container {
    height: 200px;
    width: 388px;
    margin: 0 auto;
  }

  .el-carousel__item {
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%) !important;
  }

  .el-carousel__arrow {
    background: $carousel-arrow-color;
  }

  .el-carousel__arrow--right {
    right: -14px;
  }

  .el-carousel__arrow--left {
    left: -14px;
  }
}

// RADIO BUTTON
.el-radio__input.is-checked .el-radio__inner {
  background: $dark-blue !important;
  border-color: $dark-blue !important;
}

.el-radio__inner:hover {
  border-color: $dark-blue !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: $dark-blue !important;
}

// MODAL
.el-message-box__wrapper {
  .el-message-box {
    border-radius: 14px;
    padding-bottom: 30px;
    width: 567px;
  }

  .el-button--small {
    display: none;
  }

  .el-button--primary {
    @include red-btn();
    display: block;
    margin-top: 15px;
    font-size: 14px;
  }

  .el-message-box__btns {
    display: flex;
    justify-content: center;
  }

  .el-message-box__title {
    color: $dark-blue;
    font-family: Avenir, sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
  }

  .el-message-box__header {
    padding: 20px;
    border-bottom: 1px solid $lighter-gray;
  }

  .el-message-box__content {
    padding: 15px;
  }

  .el-message-box__status::before {
    display: none;
  }

  .el-message-box__status + .el-message-box__message {
    padding: 0;
    color: $secondary;
    font-family: Avenir, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }

  .el-message-box__headerbtn:focus .el-message-box__close,
  .el-message-box__headerbtn:hover .el-message-box__close {
    color: $dark-blue;
  }
}

// // DIALOGS TABLE STYLING
.bike-modal-table,
.view-transaction-details,
.docking-station-details-modal,
.rent-tariff-details-modal {
  .el-table {
    height: 500px;
  }

  .el-table th {
    background-color: $blue-transparent !important;
    padding: 15px 0 !important;
  }

  .el-table th .cell {
    color: $dark-blue;
    opacity: 0.8;
    letter-spacing: 0;
    line-height: 19px;
  }

  .el-table td .cell {
    color: $secondary;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    width: initial;
    word-break: break-word;
  }
}

.docking-station-details-modal {
  .el-table {
    height: 650px !important;
  }

  .el-table td:first-child .cell {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

// DIALOGS TABLE STYLING



// VIEW RENT-TARIFF DETAILS DIALOG
.view-rent-tariff-details-dialog,

.view-docking-station-dialog,
.view-transaction-details-dialog {
  .el-dialog__body {
    padding: 20px 0 !important;
  }

  .el-table td,
  .el-table th {
    padding: 20px 0;
  }
}

.view-docking-station-dialog,
.view-bike-details-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-table td,
  .el-table th {
    padding: 15px 0;
  }
}

// DIALOGS TABLE
.el-dialog__body {
  .el-col-5 {
    color: $secondary;
  }

  .el-input__inner {
    color: $secondary;
    font-weight: 500;
    font-size: 16px;
  }
}

.el-tabs {
  .el-tabs__item {
    font-size: 15px !important;
    font-family: $avenir-next-demi;
  }

  .el-tabs__item.is-active,
  .el-tabs__item:hover {
    color: $tab-view-color !important;
  }

  .el-tabs__active-bar {
    background: $tab-view-color !important;
  }
}

.el-select-dropdown__item.selected {
  color: $primary !important;
}

.el-table {
  border-radius: 14px;

  .cell {
    overflow: unset !important;
  }

  td {
    .cell {
      text-overflow: initial;
      padding-left: 0 !important;
      font-size: 16px;
      color: $secondary;
    }
  }

  div {
    word-break: break-word;
  }
}

// ADD BULK FILENAME
.add-bulk {
  .doc-example {
    background: $primary;
    color: $white;
  }

  .title {
    .upload-wrapper {
      .file-name {
        color: $secondary;
      }
    }
  }
}

// ADD RENT TARIFF
.add-rent-tariff {
  .fee {
    h3 {
      color: $secondary;
    }
  }

  .fees-info {
    p {
      color: $secondary;
    }
  }
}

.view-docking-station,
.view-bike-details {
  .el-textarea {
    textarea {
      color: $secondary;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .el-table td,
  .el-table th {
    padding: 18px 0;
  }
}

// DATE PICKER OVERRIDE to CUSTOM COLORS
.el-date-table td.available:hover,
.el-picker-panel__shortcut:hover,
.el-picker-panel__icon-btn:hover {
  color: $primary !important;
}

.el-date-table td.today span {
  color: $primary !important;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: $primary !important;
}

// TRANSACTION DETAILS TABLE, REMOVE th PADDING LEFT
.view-transaction-details {
  .el-table th > .cell {
    padding-left: 0;
  }
}

// ALERT MESSAGE FONT
.el-message {
  .el-message__content {
    font-family: $avenir-next-regular;
  }
}

// custom-notify
.custom-notify {
  font-family: $avenir-next-regular;
}

// TOOLTIP CUSTOM
.el-tooltip__popper {
  width: 350px;
}

// custom pagination
.custom-pagination {
  margin: 10px 0;
  margin-left: auto;
  width: fit-content;
}

// DISABLED BTN
.disabled-btn {
  background: $disabled-btn !important;
}

// BIKE DETAILS - ADD USERS - TABS
.view-docking-station,
.view-bike-details,
.add-user {
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}

// CUSTOM PAGINATION FOR PAGES
.el-pager .number {
  background-color: $white !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $tab-view-color !important;
  color: $white !important;
}

// CUSTOM MAP DIALOG
.custom-map-dialog {
  .el-dialog {
    margin: 0 auto;
    margin-top: 5vh !important;
  }
}

// SIDEBAR
.el-menu {
  border-radius: 0 0 40px 0;
  background-image: linear-gradient($gradient-start, $gradient-end);

  li {
    margin: 5px 0;
  }

  .el-menu-item,
  .sub-menu-link {
    overflow: hidden;
  }

  span {
    display: inline-block;
  }

  &.scroll-el-menu {
    height: 100%;
    min-width: 330px;
    overflow-y: scroll;
  }
}

.el-menu-wrapper {
  position: fixed !important;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 270px;
  transition: 0.3s;
  will-change: transform;
  transform: translateX(0px);
  z-index: 100;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  &.closed-menu {
    transform: translateX(-270px);
  }

  .img-wrapper {
    display: inline-block;
    text-align: center;
    width: 30px;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    img {
      opacity: 0.6;
    }
  }

  .el-submenu__title {
    &:hover {
      span {
        color: #fff;
      }

      .img-wrapper {
        img {
          opacity: 1;
        }
      }
    }

    .router-link-active {
      span {
        color: #fff;
      }

      .img-wrapper {
        img {
          opacity: 1;
        }
      }
    }
  }

  .el-submenu {
    background-color: transparent;

    .el-menu-item {
      margin: 0;
      padding-left: 66px !important;
    }

    &.is-active {
      .router-link-active {
        span,
        i {
          color: white;
        }

        img {
          opacity: 1;
        }
      }
    }

    .el-menu {
      border-radius: 0;
      background: transparent;
    }
  }

  .el-menu-item {
    background-color: transparent;

    &.is-active,
    &:hover,
    &:focus {
      background: transparent;

      span {
        color: $white !important;
      }

      img {
        opacity: 1;
      }
    }
  }

  .el-menu-item i,
  .el-submenu__title i {
    color: $light-gray;
  }

  .el-submenu__title:hover {
    background: transparent;
  }

  i {
    color: $light-gray;
  }

  span {
    font-weight: thin;
    font-size: 16px;
    color: $light-gray;
  }
}

.el-menu-wrapper::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}