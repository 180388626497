.docking-stations-list-view,
.docking-station-logs-view,
.customers-view,
.docking-stations-map,
.geo-fences-map,
.bikes-map,
.bikes-view,
.logs-view,
.technical-dashboard-view,
.station-commands-view,
.rent-tariffs-view,
.users-view,
.user-view,
.roles-view,
.add-user-view,
.edit-user-view,
.role-view,
.edit-role-view,
.add-role-view,
.transactions-view,
.customer-view,
.cost-centers-view,
.tickets-view,
.customer-tickets-view,
.settings-view,
.configurable-settings-view,
.tickets-settings-view,
.inventory-view,
.rides-view,
.ticket-view,
.tracker-view {
  margin-top: 40px;
  padding-right: 30px;
  min-width: 99%;

  h3 {
    color: $dark-blue;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    font-family: $avenir-next-bold;
  }

  .el-table td .cell {
    font-size: 16px;
    color: $secondary;
    line-height: 22px;
    font-family: $avenir-next-regular;
    word-break: break-word;
  }

  // make checkboxes like in design
  .green-check {
    background: $green-btn !important;
    border-color: transparent;
  }

  .text-cut {
    text-decoration: line-through;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: $blue-btn;
    border-color: transparent;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: $blue-btn;
    border-color: transparent;
  }

  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .el-checkbox__inner::after {
    height: 8px;
    left: 5px;
    top: 1px;
    width: 4px;
    border: 2px solid $white;
    border-left: 0;
    border-top: 0;
  }

  .el-checkbox {
    color: $secondary;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $secondary;
  }

  .el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover {
    color: $primary;
  }

  .last-breadcrumb-bold {
    .is-link {
      font-weight: normal;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      font-weight: bolder;
      color: $secondary;
    }
  }

  .middle-breadcrumb-bold {
    .is-link {
      font-weight: normal;
    }

    .el-breadcrumb__item:nth-child(2) .el-breadcrumb__inner {
      font-weight: bolder;
      color: $secondary;
    }
  }

  // collapse
  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item__header {
    border-bottom: none;
  }

  .docking-stations-wrapper,
  .docking-station-logs-wrapper,
  .geo-fences-wrapper,
  .customers-wrapper,
  .roles-table-wrapper,
  .bikes-wrapper,
  .trackers-wrapper,
  .logs-wrapper,
  .technical_dashboard-wrapper,
  .users-wrapper,
  .roles-wrapper,
  .add-user-wrapper,
  .edit-user-wrapper,
  .view-role-wrapper,
  .view-user-wrapper,
  .edit-role-wrapper,
  .add-role-wrapper,
  .rides-wrapper,
  .rent-tariffs-wrapper,
  .tickets-wrapper,
  .customer-tickets-wrapper,
  .inventory-wrapper,
  .configurable-settings-wrapper,
  .tickets-settings-wrapper,
  .cost-centers-wrapper {
    border: 1px solid $lighter-gray;
    border-radius: 14px;
    background-color: $white;
    margin-top: 25px;
    padding-bottom: 25px;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .actions {
      padding: 26px;

      .el-input {
        width: 227px;
      }
    }

    .table-header {
      opacity: 0.8;
      background-color: $lighter-gray;
      padding: 15px;

      .cell {
        opacity: 0.8;
        color: $dark-blue;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        font-family: $avenir-next-bold;
        white-space: pre-line;
        word-break: break-word;
      }
    }

    // user view and role view permissions list
    .permissions-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .check-icon {
        color: $primary;
      }

      .x-icon {
        color: $red;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  // STATISTICS WRAPPER
  @media only screen and (max-width: 1600px) {
    .statistics {
      width: fit-content;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: unset;
    }
  }

  .tracker-wrapper {
    border-radius: 14px;
    background-color: $white;
    margin-top: 25px;
    padding-bottom: 25px;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .actions {
      padding: 26px;

      .el-input {
        width: 227px;
      }
    }

    .table-header {
      opacity: 0.8;
      background-color: $lighter-gray;

      .cell {
        opacity: 0.8;
        color: $dark-blue;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        font-family: $avenir-next-bold;
        white-space: pre-line;
        word-break: break-word;
      }
    }

    // user view and role view permissions list
    .permissions-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .check-icon {
        color: $primary;
      }

      .x-icon {
        color: $red;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }
}

.rent-tariffs-view {
  .rent-tariff-top-page-wrapper {
    margin-top: 20px;
  }
}

.view-bike-maintenance {
  .el-dialog {
    margin-top: 8vh !important;
  }
}

.view-customer-tickets-details-dialog,
.view-ride-details-dialog,
.view-add-bike-dialog,
.view-assign-bike-dialog,
.view-add-new-bike-dialog,
.view-bike-maintenance,
.view-add-bulk-dialog {
  .customer-tickets-view,
  .customer-tickets-wrapper {
    margin: 0;
    padding: 0;
  }

  .customer-tickets-wrapper {
    border: 0;
  }

  .el-input__inner {
    color: $tundora;
    font-weight: 600;
    font-size: 14px;
  }
}

.transaction-tariff-ammount {
  color: $dark-blue;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  font-family: $avenir-next-demi;
}

// CUSTOM TRANSACTIONS STATISTICS BOX
.transactions-view {
  .info-box-statistic {
    padding-bottom: 14px;

    h4 {
      font-size: 16px !important;
      padding-top: 14px;
    }

    .statistic {
      padding: 0px 20px;
    }

    .info > span {
      font-size: 24px !important;
    }
  }
}

// filters wrapper
.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .switch-buttons{
    padding-top: 30px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    gap: 15px;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.trackers-wrapper {
  .actions {
    display: flex;
    flex-direction: row;

    .tracker-data-actions {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .switch{
        margin: auto;
      }
    }
  }
}




@media (max-width: 600px) {
  .customers-view{
    padding: 0px;
    width: 100%;
  }

  .switch-buttons{
    flex-direction: column !important;
  }

  .trackers-wrapper
    .actions {
      display: flex;
      flex-direction: column;

      .tracker-data-actions {
        margin: auto;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }


.user-date-filter,
.transactions-date-filter,
.transactions-cost-center-filter,
.rent-tariff-filter,
.date-filter,
.docking-stations-filter,
.tickets-filter,
.customers-filter,
.cost-centers-filter {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .el-range-editor.el-input__inner {
    width: 340px;
  }

  .el-range-editor.el-input__inner,
  .el-select .el-input__inner {
    border-radius: 8px;
    border: none;
  }

  .el-date-editor .el-range-input {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    font-family: $avenir-next-demi;
  }

  .filter-title {
    font-size: 16px;
    color: $dark-blue;
    font-weight: 600;
    opacity: 0.5;
    line-height: 22px;
    margin-bottom: 6px;
    font-family: $avenir-next-demi;
  }
}

.customer-view {
  .prices-wrapper {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    .price {
      width: 100%;
      border-radius: 12px;
      background-color: $white;
      padding: 13px 0;
      justify-content: center;
      align-items: center;

      &:nth-child(2) {
        margin: 0 20px;
      }

      .price-info {
        margin-left: 20px;

        .title {
          margin-bottom: 9px;
        }
      }
    }
  }

  .view-tickets-btn {
    background: $blue-btn !important;
  }

  .see-contact-details-btn {
    background: $dark-blue !important;
  }

  .extra-charge-btn {
    background: $green-btn !important;
  }

  .restrict-btn {
    background: $danger-btn !important;
  }

  .device-info-btn {
    background: $device-info-btn !important;
  }
}

.tracker-view {

  .title {
    font-size: 24px; /* Increase font size */
    font-weight: bold; /* Make the font bold */
    padding: 10px; /* Add some padding */
    margin-bottom: 10px; /* Add margin to separate from the content */
    border-radius: 5px; /* Optional: rounded corners */
  }

  .prices-wrapper {
    display: grid;
    grid-template-columns: 45% 53%;
    gap: 10px; /* Space between the grid items */
    align-content: center;
    margin-top: 20px;
    background-color: $light-blue;
    width: 98%; 
    // height: 98%;
    border-radius: 15px;

    .map {
      margin: 10px;
      padding: 10px;
      background-color: $white;
      border-radius: 15px;
    }

    .version {
      display: flex;
      grid-column: 1; /* Place box6 in the first column */
      grid-row: 2; /* Place box6 in the second row */
      margin: 10px;
      padding: 10px;
      background-color: $white;
      border-radius: 15px;
      .info {
        // width: 50%;
        margin: auto;
        // padding: auto;
      }
    }

    .chart {
      grid-column: 2; /* Place box2 in the second column */
      grid-row: 1 / span 2; /* Span box2 from first to third row */
      margin: 10px;
      padding: 10px;
      background-color: $white;
      border-radius: 15px;
    }

    .connection {
      display: flex;
      margin: 10px;
      padding: 10px;
      grid-column: span 2;
      grid-row: 3; 
      background-color: $white;
      border-radius: 15px;

      .types {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: auto;
      }
      // span {
      //   margin-top: 5px; /* Add some margin between the spans */
      //   text-align: center; /* Center-align the text */
      //   width: fit-content; /* Make spans take only as much width as needed */
      // }
    }

    .bike {
      display: flex;
      margin: 10px;
      padding: 10px;
      grid-column: span 2;
      grid-row: 4; 
      background-color: $white;
      border-radius: 15px;

      .types {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        span {
          flex: 1 1 calc(33.333% - 10px);
          margin: 5px;
          box-sizing: border-box;
        }
      }
      
    }

    .event {
      margin: 10px;
      padding: 10px;
      grid-column: span 2;
      grid-row: 5;
      background-color: $white;
      border-radius: 15px;


      .types {
        display: grid;
        grid-template-columns: 98% 15%; /* 60% for chart and 35% for spans */
        grid-gap: 5%; /* Gap between the chart and spans */
        align-items: center; /* Center items vertically */
      }
      
      // .types > canvas {
      //   width: 100%; /* Ensure the chart takes full width of its grid cell */
      //   box-sizing: border-box;
      // }

      .rotation {
        grid-column: 1; /* Place box6 in the first column */
        grid-row: 1; /* Place box6 in the second row */
      }
      .status {
        grid-column: 2; /* Place box6 in the first column */
        grid-row: 1; /* Place box6 in the second row */
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Vertical layout for spans */
        justify-content: center; /* Center vertically */
        margin: auto;
      }
      
      .status span {
        margin-bottom: 10px; /* Spacing between spans */
      }
      
      // .status span:last-child {
      //   margin-bottom: 0; /* Remove margin from the last span */
      // }
    }

    .details {
      margin: 10px;
      padding: 10px;
      grid-column: span 2;
      grid-row: 6;
      background-color: $white;
      border-radius: 15px;
    }



  /* Media query for small screens */
  }

  @media (max-width: 600px) {
    .prices-wrapper {
      grid-template-columns: 1fr;
      grid-auto-rows: min-content;
    }

    .map {
      grid-column: span 1;
      grid-row: 1;
    }

    .version {
      grid-column: span 1;
      grid-row: 2;
      flex-direction: column;
      .info {
        // grid-template-columns: 1fr 1fr;
        // width: 50%;
        margin: auto;
        // padding: auto;
      }
    }

    .chart {
      grid-column: span 1;
      grid-row: 3;
    }

    .connection {
      grid-column: span 1;
      grid-row: 4;
      flex-direction: column;
      // align-items: center;

      .types {
        display: flex;
        flex-direction: column; /* Stacks the spans vertically */
        // align-items: center; /* Aligns items horizontally in the center */
        // display: block;

        span {
          margin: 0; /* Reset margin */
        }
      }

    }

    .bike {
      grid-column: span 1;
      grid-row: 5;
      flex-direction: column;
      // align-items: center;

      .types {
        span {
          flex: 1 1 calc(50% - 10px);
        }
      }

    }

    .event {
      grid-column: span 1;
      grid-row: 6;
    }

    .details {
      grid-column: span 1;
      grid-row: 7;
    }
  }

  .view-tickets-btn {
    background: $blue-btn !important;
  }

  .see-contact-details-btn {
    background: $dark-blue !important;
  }

  .extra-charge-btn {
    background: $green-btn !important;
  }

  .restrict-btn {
    background: $danger-btn !important;
  }

  .device-info-btn {
    background: $device-info-btn !important;
  }
}

// dialog view details
.view-contact-details,
.view-device-info-details,
.view-bike-details,
.view-ticket-details,
.view-transaction-details,
.view-ride-details,
.view-docking-station-details,
.view-rent-tariff-details,
.view-docking-station-details,
.view-docking-station-statistics {
  .details-bold .el-col span {
    font-family: $avenir-next-demi;
    color: $secondary;
  }

  // el description
  .el-descriptions-item__label:not(.is-bordered-label) {
    font-family: $avenir-next-demi;
    color: $secondary;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    color: $secondary;
  }

  //---------------
  .text-normal .el-col span {
    color: $secondary;
  }
}

// BIKES VIEW
.bikes-view {
  .tab-view {
    .el-tabs__nav {
      display: flex;
      width: 100%;
    }
  }
}

// SECONDARY INPUTS
.transactions-view,
.customers-view {
  .db-search {
    margin-left: 10px;
    width: 277px !important;
  }
}

// BUTTONS
.delete-btn {
  box-shadow: 0 10px 20px 0 rgba(228, 30, 38, 0.21);
  color: white !important;
  background: #e41e26 !important;
}


.bike-history {
  .bike_history_table {
    height: 650px !important;
  }
}

.bike-maintenance-history {
  .bike_maintenance_table {
    height: 650px !important;
  }
}
